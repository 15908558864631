import Cookie from 'js-cookie';

export function authHeader() {
    // return authorization header with jwt token
    let user = {token: Cookie.get('_easyindustria_token')};
    
    if (user && user.token) {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+user.token
        };
    } else {
        return {};
    }
}